// src/components/Layout.js
import React, { useEffect } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import ResponsiveAppBar from './AppBar';
import { Box, Container, Typography, Link as MuiLink, Breakpoint } from '@mui/material';
import theme from '../theme';
import { trackPageView } from '../utils/analytics';

function Copyright() {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body2" color="textSecondary" sx={{ display: 'inline' }}>
        {"© "}
        <MuiLink color="inherit" href="https://ResumeFactory.ai/">
          ResumeFactory.ai
        </MuiLink>{" "}
        {new Date().getFullYear()}
        {' • '}
        <MuiLink component={Link} to="/privacy" color="inherit">
          Privacy Policy
        </MuiLink>
        {' • '}
        <MuiLink component={Link} to="/terms" color="inherit">
          Terms of Service
        </MuiLink>
      </Typography>
      <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 0.5 }}>
        This site uses cookies and Google Analytics to analyze traffic. Analytics respects Do Not Track.{' '}
        <MuiLink component={Link} to="/privacy" color="inherit">
          Learn more
        </MuiLink>
      </Typography>
    </Box>
  );
}

const Layout: React.FC<{ user: any, containerDisableGutters?: boolean, containerMaxWidth?: Breakpoint|boolean }> = ({ user, containerDisableGutters, containerMaxWidth }) => {
  containerDisableGutters = containerDisableGutters || false;
  if (!containerMaxWidth && containerMaxWidth !== false) {
    containerMaxWidth = containerMaxWidth || 'xl';
  }

  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: "flex",
        flexDirection: "column",
        background: `linear-gradient(135deg, ${theme.palette.primary.backgroundLight} 30%, ${theme.palette.secondary.backgroundLight} 90%)`,
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.05)',
          pointerEvents: 'none',
        },
      }}
    >
      <ResponsiveAppBar user={user} containerMaxWidth={containerMaxWidth} containerDisableGutters={containerDisableGutters}/>

      {/* @ts-ignore */}
      <Container component="main" maxWidth={containerMaxWidth} disableGutters={containerDisableGutters}>
        <Outlet />
      </Container>
      
      <Box
        component="footer"
        sx={{
          marginTop: 'auto',
          py: 1.5,
          px: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          backdropFilter: 'blur(4px)',
          borderTop: `1px solid ${theme.palette.grey[200]}`,
        }}
      >
        <Container 
          maxWidth={containerMaxWidth as Breakpoint | false} 
          disableGutters={containerDisableGutters}
        >
          <Copyright />
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;